import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { appReducers } from './appReducers';
import { apiMiddleware, apiReducers } from './api';

// Note: Enable the rtkQueryError middleware when it was decided how to handle errors
// import { rtkQueryError } from './middleware/rtkQueryError';

// -------------------------------------------------------

export const store = configureStore({
  reducer: combineReducers({
    ...appReducers,
    ...apiReducers
  }),

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Added to avoid the warning: Error: A state mutation was detected between dispatches
      // TODO: Remove this when legacy code is removed
      immutableCheck: false,
      serializableCheck: false,
    })
      // .concat(rtkQueryError)
      .concat(apiMiddleware),

  // Enable Redux DevTools in development mode
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
