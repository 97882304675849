const initialState = {
  projects: null,
  fetchProjects: false,
  shouldUpdateData: true,
  showAddProjectModal: false,
  loadMoreToken: "",
  showEditProjectModal: { show: false, projectId: null },
  newProjects: [],
  panels: null
};

function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROJECTS_DATA':
      return {
        ...state,
        projects: action.payload
      };
    case 'UPDATE_PROJECTS_DATA':
      return {
        ...state,
        newProjects: action.payload
      };
    case 'UPDATE_FETCH_PROJECTS':
      return {
        ...state,
        fetchProjects: action.payload
      };
    case 'UPDATE_PANELS':
      return {
        ...state,
        panels: action.payload
      };
    case 'SET_LOAD_MORE_TOKEN':
      return {
        ...state,
        loadMoreToken: action.payload
      };
    case 'SHOULD_FETCH_PROJECTS':
      return {
        ...state,
        shouldUpdateData: action.payload
      };
    case 'UPDATE_SHOW_ADD_PROJECT_MODAL':
      return {
        ...state,
        showAddProjectModal: action.payload
      };
    case 'UPDATE_SHOW_EDIT_PROJECT_MODAL':
      return {
        ...state,
        showEditProjectModal: {
          show: action.payload.show,
          projectId: action.payload.id
        }
      };
    default:
      return state;
  }
}

export default projectsReducer;