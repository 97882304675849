
const initialState = {
  theData: {
    theData: {
      data: [],
      originalData: {},
      selectedItem: null,
      selectedItemReview: [],
      originalSelectedItem: null,
      hasChanges: false,
      allOptions: [],
      checkedElements: [],
      checkedElementsObjects: [],
      elementTypes: [],
      dropdownValues: [],
      optionsType: null,
      displayOptions: false,
      reloadOptions: false,
      filterExpression: null,
      displayFilterBuilder: false,
      filterData: [],
      logicStructureData: null,
      combineFilterData: [],
      loadingState: false,
      showLogic: true,
      htmlMode: false,
      validationErrors: [],
      displayScript: false,
      displayCSS: false,
      pastePad: '',
      selectedQuestion: {},
      surveyStatus: null,
      versions: null,
      isValidating: null,
      changeType: null,
      updateVisualisation: { shouldUpdate: false, data: null },
      lists: [],
      selectedListIndex: 0,
      openListModalView: false,
      selectedSubquestion: 0,
      editingElement: null,
      lastEditingContent: null,
      selectedItemOptionElements: null,
      visualisationSettings: [],
      supportedFonts: [],
      loadingMessage: null,
      needsValidation: false,
      displayPastePad: false,
      displaySectionCSS: { show: false, sectionID: 0 },
      surveyReviews: [],
      isSurveyComments: false,
      isSurveyCommentsLoading: true,
      toggleDisabledQuestions: false,
      editingLanguage: "",
      dcV2: null,
      showElementLogic: false
    },
    selectedElement: null,
    isLoadingOptions: false,
    shouldSaveQuestion: false,
    shouldSaveAdvancedQuestion: false,
    shouldSaveIndex: false,
    autoSaveStatus: '',
    tags: null,
    themes: null,
    themeId: null,
    themeCss: '',
    themeJs: '',
    systemLanguages: null,
    shouldUpdatelanguages: "not started",
    customQuestionsData: null,
    groupedCustomQuestionsData: null,
    showErrorLog: false,
    translationsExportStatus: null,
    emailProviders: {
      loading: false,
      data: []
    }
  }
};

function setSurveyInitialDataReducer(state = initialState.theData, action) {
  switch (action.type) {
    case 'SET_SURVEY_INITIAL_DATA':
      return {
        ...state,
        theData: {
          ...state.theData,
          data: action.payload.data,
          originalData: action.payload.originalData,
          oldData: action.payload.data,
          editingLanguage: action.payload.originalData.languages[0].code,
          selectedItem: null,
          selectedItemReview: [],
          originalSelectedItem: null,
          editingElement: null,
          lastEditingContent: null,
          hasChanges: false,
          selectedItemOptionElements: null,
          allOptions: [],
          checkedElements: [],
          elementTypes: [],
          dropdownValues: [],
          visualisationSettings: [],
          supportedFonts: [],
          optionsType: null,
          displayOptions: false,
          reloadOptions: false,
          filterExpression: null,
          displayFilterBuilder: false,
          filterData: [],
          logicStructureData: null,
          combineFilterData: [],
          loadingState: false,
          loadingMessage: null,
          showLogic: true,
          htmlMode: action.payload.htmlMode,
          validationErrors: [],
          needsValidation: false,
          displayScript: false,
          displayPastePad: false,
          selectedSubquestion: 0,
          pastePad: '',
          surveyReviews: [],
        }
      }
    case 'SET_LOGIC_STRUCTURE_DATA':
      return {
        ...state,
        theData: {
          ...state.theData,
          logicStructureData: action.payload
        }
      }
    case 'SET_CUSTOM_QUESTIONS_DATA':
      return {
        ...state,
        customQuestionsData: action.payload
      }
    case 'SET_GROUPED_CUSTOM_QUESTIONS_DATA':
      return {
        ...state,
        groupedCustomQuestionsData: action.payload
      }
    case 'SAVE_QUESTION':
      return {
        ...state,
        shouldSaveAdvancedQuestion: false,
        shouldSaveQuestion: true,
      }
    case 'SAVE_ADVANCED_QUESTION':
      return {
        ...state,
        shouldSaveAdvancedQuestion: true,
        shouldSaveQuestion: true,
      }
    case 'SAVE_INDEX':
      return {
        ...state,
        shouldSaveIndex: true
      }
    case 'SET_SHOULD_SAVE_QUESTION':
      return {
        ...state,
        shouldSaveQuestion: action.payload
      }
    case 'SET_SHOULD_SAVE_INDEX':
      return {
        ...state,
        shouldSaveIndex: action.payload
      }
    case 'TOGGLE_DISABLED_QUESTIONS':
      return {
        ...state,
        theData: {
          ...state.theData,
          toggleDisabledQuestions: action.payload
        }
      }
    case 'SET_AUTOSAVE_STATUS':
      return {
        ...state,
        autoSaveStatus: action.payload
      }
    case 'TOGGLE_SHOW_ELEMENT_LOGIC':
      return {
        ...state,
        theData: {
          ...state.theData,
          showElementLogic: action.payload
        }
      }
    case 'SET_SELECTED_ELEMENT':
      return {
        ...state,
        selectedElement: action.payload,
      }
    case 'SET_SELECTED_ELEMENT_COMMENTS':
      return {
        ...state,
        theData: {
          ...state.theData,
          isSurveyComments: action.payload
        }
      }
    case 'SET_IS_LOADING_OPTIONS':
      return {
        ...state,
        isLoadingOptions: action.payload,
      }
    case 'SET_IS_LOADING_COMMENTS':
      return {
        ...state,
        theData: {
          ...state.theData,
          isSurveyCommentsLoading: action.payload,
        }
      }
    case 'SET_CHANGE_TYPE':
      return {
        ...state,
        theData: {
          ...state.theData,
          changeType: action.payload
        }
      }
    case 'SET_SURVEY_PUBLISH_DATA':
      return {
        ...state,
        theData: {
          ...state.theData,
          surveyStatus: action.payload.surveyStatus,
          versions: action.payload.versions,
          dcV2: action.payload.dcV2
        }
      }
    case 'SEARCH_SURVEY_DATA':
      const copyTheData = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copyTheData,
          data: action.payload
        }
      }
    case 'SET_SURVEY_OPTIONS':
      const copy = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copy,
          allOptions: action.payload.allOptions,
          elementTypes: action.payload.elements,
          dropdownValues: action.payload.dropdownValues,
          visualisationSettings: action.payload.visualisationSettings,
          supportedFonts: action.payload.supportedFonts
        }
      }
    case 'UPDATE_ORIGINAL_DATA':
      return {
        ...state,
        theData: {
          ...state.theData,
          originalData: action.payload
        }
      }
    case 'UPDATE_SURVEY_DATA':
      const stateCopy = { ...state.theData }
      return {
        ...state,
        theData: {
          ...stateCopy,
          data: action.payload.data,
          originalData: action.payload.originalData,
          oldData: action.payload.data,
        }
      }
    case 'LOAD_SURVEY_ELEMENT_DATA':
      const allCopy = { ...state.theData }
      return {
        ...state,
        theData: {
          ...allCopy,
          loadingState: true,
          loadingMessage: action.payload,
        }
      }
    case 'FORCE_STOP_LOADING':
      const theCopy = { ...state.theData }
      return {
        ...state,
        theData: {
          ...theCopy,
          loadingState: false,
        }
      }
    case 'UPDATE_SURVEY_ELEMENT_DATA':
      const elementCopy = { ...state.theData }
      return {
        ...state,
        theData: {
          ...elementCopy,
          selectedItem: action.payload.item,
          originalSelectedItem: action.payload.originalItem,
          selectedItemOptionElements: action.payload.elementOptions,
          hasChanges: false,
          loadingState: elementCopy.needsValidation ? true : false,
          loadingMessage: elementCopy.needsValidation ? elementCopy.loadingMessage : null,
        }
      }
    case 'SURVEY_DATA_SELECT_ELEMENT':
      const copyState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copyState,
          data: action.payload.data,
          selectedItem: action.payload.item,
          originalSelectedItem: action.payload.originalItem,
          hasChanges: false,
          selectedItemOptionElements: action.payload.elementOptions,
          loadingState: copyState.needsValidation ? true : false,
          loadingMessage: copyState.needsValidation ? copyState.loadingMessage : null,
          editingElement: null,
          lastEditingContent: null,
          selectedSubquestion: 0,
          selectedItemReview: action.payload.selectedItemReview
        }
      }
    case 'SURVEY_DATA_CHECK_ELEMENTS':
      const copyCheckedState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copyCheckedState,
          checkedElements: action.payload
        }
      }
    case 'SURVEY_DATA_CHECK_ELEMENTS_OBJECTS':
      const copiedCheckedState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedCheckedState,
          checkedElementsObjects: action.payload
        }
      }
    case 'SURVEY_DISPLAY_OPTIONS':
      const copiedState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedState,
          optionsType: action.payload.type,
          displayOptions: action.payload.display,
          displayScript: false,
          displayPastePad: false,
        }
      }
    case 'SURVEY_DISPLAY_SCRIPT':
      const copiedScriptState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedScriptState,
          displayScript: action.payload,
          displayOptions: false,
          displayPastePad: false,
        }
      }
    case 'SURVEY_DISPLAY_CSS':
      const theCopyOfTheRealState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...theCopyOfTheRealState,
          displayCSS: action.payload,
          displayOptions: false,
          displayPastePad: false,
        }
      }
    case 'SURVEY_DISPLAY_PASTE_PAD':
      const copiedPadState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedPadState,
          displayPastePad: action.payload,
          displayScript: false,
          displayOptions: false,
        }
      }
    case 'SURVEY_SET_OPTIONS_TYPE':
      const copiedOptionsState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedOptionsState,
          optionsType: action.payload.type
        }
      }
    case 'SURVEY_SET_SUBQUESTION_INDEX':
      const copiedSubqState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedSubqState,
          selectedSubquestion: action.payload
        }
      }
    case 'SURVEY_SET_EDITING_ELEMENT':
      const editingState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...editingState,
          editingElement: action.payload
        }
      }
    case 'SURVEY_SET_LAST_EDITING_CONTENT':
      const editingContentState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...editingContentState,
          lastEditingContent: action.payload
        }
      }
    case 'SURVEY_RELOAD_OPTIONS':
      // Triggers a reload of Survey Options for the selected Survey Element
      const copyOptionsState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copyOptionsState,
          reloadOptions: action.payload.reload,
          filterExpression: action.payload.expression
        }
      }
    case 'ON_EXPAND_SECTION':
      const copied = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copied,
          data: action.payload
        }
      }
    case 'ON_EXPAND_SUBQUESTION':
      const prevState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...prevState,
          selectedItem: action.payload
        }
      }
    case 'SURVEY_DISPLAY_FILTER_BUILDER':
      const prevCopyState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...prevCopyState,
          displayFilterBuilder: action.payload.display,
          filterData: action.payload.filterData,
          combineFilterData: action.payload.combineFilterData,
          filterBuilderOptionId: action.payload.filterBuilderOptionId
        }
      }
    case 'UPDATE_SURVEY_REVIEW_SELECT_ELEMENT':
      return {
        ...state,
        theData: {
          ...state.theData,
          selectedItemReview: action.payload.selectedItemReview
        }
      }
    case 'UPDATE_SURVEY_REVIEW':
      return {
        ...state,
        theData: {
          ...state.theData,
          surveyReviews: action.payload.surveyReviews
        }
      }
    case 'UPDATE_SURVEY_OPTION_ELEMENTS':
      const prevSurveyState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...prevSurveyState,
          originalSelectedItem: action.payload.originalItem,
          selectedItem: action.payload.selectedItem,
          selectedItemOptionElements: action.payload.optionElements,
        }
      }
    case 'UPDATE_SURVEY_EDITING_LANGUAGE':
      const previousState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...previousState,
          editingLanguage: action.payload,
          loadingState: false,
        }
      }
    case 'UPDATE_SURVEY_VALIDATION_PROCESS':
      const previousValidatedState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...previousValidatedState,
          needsValidation: true,
        }
      }
    case 'UPDATE_SURVEY_VALIDATION_ERRORS':
      const prevValidatedState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...prevValidatedState,
          validationErrors: action.payload.errors,
          originalSelectedItem: action.payload.originalItem,
          selectedItem: action.payload.selectedItem,
          data: action.payload.data,
          hasChanges: false,
          needsValidation: false,
          loadingMessage: null,
          loadingState: false
        }
      }
    case 'UPDATE_SURVEY_ONLY_VALIDATION_ERRORS':
      const prevValidatedData = { ...state.theData }
      return {
        ...state,
        theData: {
          ...prevValidatedData,
          validationErrors: action.payload,
          hasChanges: false,
          needsValidation: false,
          loadingMessage: null,
          loadingState: false
        }
      }
    case 'UPDATE_SURVEY_SHOW_LOGIC':
      const previousShowLogicState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...previousShowLogicState,
          showLogic: action.payload,
        }
      }
    case 'UPDATE_SURVEY_HTML_MODE':
      const previousHtmlModeState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...previousHtmlModeState,
          htmlMode: action.payload,
        }
      }
    case 'UPDATE_SURVEY_PASTE_PAD':
      const previousPastePadState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...previousPastePadState,
          pastePad: action.payload,
        }
      }
    case 'UPDATE_SURVEY_LANGUAGES_DATA':
      const oldStateData = { ...state.theData }
      return {
        ...state,
        theData: {
          ...oldStateData,
          originalData: {
            ...oldStateData.originalData,
            languages: action.payload
          }
        }
      }
    case 'UPDATE_QUOTASTOP_REFERENCE_QUOTA':
      const oldState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...oldState,
          selectedItem: {
            ...oldState.selectedItem,
            referenceQuotas: {
              ...oldState.selectedItem.referenceQuotas,
              [action.mode]: action.payload,
            }
          }
        }
      }
    case 'UPDATE_SELECTED_QUESTION':
      const oldestState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...oldestState,
          selectedQuestion: action.payload
        }
      }
    case 'ADD_LIST':
      const theOldestState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...theOldestState,
          originalData: {
            ...theOldestState.originalData,
            lists: action.payload
          }
        }
      }
    case 'ADD_LIST_ADVANCED':
      return {
        ...state,
        shouldSaveAdvancedQuestion: true,
        theData: {
          ...theOldestState,
          originalData: {
            ...theOldestState.originalData,
            lists: action.payload
          }
        }
      }
    case 'SET_SELECTED_LIST_INDEX':
      const theOldestOfState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...theOldestOfState,
          selectedListIndex: action.payload
        }
      }
    case 'OPEN_LIST_MODAL_VIEW_DIALOG':
      const theOldestOfAllState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...theOldestOfAllState,
          openListModalView: action.payload
        }
      }
    case 'SET_TAGS':
      return {
        ...state,
        tags: action.payload
      }
    case 'SET_THEMES':
      return {
        ...state,
        themes: action.payload
      }
    case 'SET_THEME_ID':
      return {
        ...state,
        themeId: action.payload
      }
    case 'SET_THEME_CSS':
      return {
        ...state,
        themeCss: action.payload
      }
    case 'SET_THEME_JS':
      return {
        ...state,
        themeJs: action.payload
      }
    case 'SET_SYSTEM_LANGUAGES':
      return {
        ...state,
        systemLanguages: action.payload
      }
    case 'SHOULD_UPDATE_LANGUAGES':
      const theOldestOfAllTheStates = { ...state }
      return {
        ...theOldestOfAllTheStates,
        shouldUpdatelanguages: action.payload
      }
    case 'SET_TRANSLATIONS_EXPORT_STATUS':
      return {
        ...state,
        translationsExportStatus: action.payload
      }
    case 'SET_SHOW_ERROR_LOG':
      return {
        ...state,
        showErrorLog: action.payload
      }
    case 'SURVEY_SECTION_LEVEL_DISPLAY_SCRIPT':
      const copiedLastScriptState = { ...state.theData }
      return {
        ...state,
        theData: {
          ...copiedLastScriptState,
          displaySectionCSS: action.payload
        }
      }
    case 'SET_EMAIL_PROVIDERS':
      return {
        ...state,
        emailProviders: action.payload
      }
    default:
      return state;
  }
}
export default setSurveyInitialDataReducer;
