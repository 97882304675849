import { onDragEnd } from "../../../components/Analysis/Analyze/components/shared/helpers/onDragEnd/onDragEnd";
import { onDragStart } from "../../../components/Analysis/Analyze/components/shared/helpers/onDragStart/onDragStart";
import { toplineClearQuestions } from "../../../components/Analysis/Analyze/components/shared/helpers/toplineClearQuestions/toplineClearQuestions";

const initialState = {
  theState: {
    state: {
      optionId: [],
      initialOptionsData: {
        initialUniverseData: [],
        initialQuestionOptions: {},
        initialNewQuestionOptions: {},
      },
      initialAndUpdateOptionsValues: {
        initialOptionsValues: {},
        updateOptionsValues: {}
      },
      firstColumn: []
    },
    source: {},
    destination: {},
    removeItem: { 'id': null, 'item': null },
    questions: { value: '' },
    titles: [],
    undoQuestionOptions: [],
    redoQuestionOptions: []
  }
};

function digStateReducer(state = initialState.theState, action) {
  switch (action.type) {
    case 'UPDATE_INITIAL_DIG_STATE':
      return {
        ...state,
        state: {
          ...state.state,
          ...action.payload
        }
      }
    case 'DIG_SET_INITIAL_STATE':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: [],
          filterData: [],
          universeData: [],
          targetGroup: [],
          columns: [],
          secondColumnIndex: [],
          data: {},
          secondColumns: { value: '' },
          displayTable: null,
          checkedNum: [],
          selectedQuestionSource: null,
        },
        source: {},
        destination: {},
        removeItem: { 'id': null, 'item': null },
        questions: { value: '' },
        titles: []
      }
    case 'DIG_PASTE_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          updateTable: true
        }
      }
    case 'UPDATE_DIG_UNDO_QUESTION_OPTIONS':
      return {
        ...state,
        undoQuestionOptions: action.payload
      }
    case 'DIG_UNDO_LAST_CHANGE':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          updateTable: true
        },
        undoQuestionOptions: action.payload.undoQuestionOptions,
        redoQuestionOptions: action.payload.redoQuestionOptions
      }
    case 'DIG_REDO_LAST_CHANGE':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
          updateTable: true
        },
        undoQuestionOptions: action.payload.undoQuestionOptions,
        redoQuestionOptions: action.payload.redoQuestionOptions
      }
    case 'SET_INITIAL_DIG_OPTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions
        }
      }
    case 'DIG_ON_DRAG_START':
      return onDragStart(
        action.payload.result,
        state.state.secondColumnIndex,
        state,
        state.removeItem)
    case 'DIG_ON_DRAG_END':
      return onDragEnd(
        action.payload.result,
        state,
        state.source,
        state.destination,
        state.removeItem,
        state.state.secondColumnIndex,
        state.state.columns,
        action.payload.theData,
        action.payload.rangeItems)
    case 'DIG_SET_ACTIVE_ROW':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload,
          updateTable: true
        }
      }
    case 'DIG_CLEAR_QUESTIONS':
      return toplineClearQuestions(state, action.payload)
    case 'DIG_CLEAR_QUESTION_ROWS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: [],
          displayTable: null,
          reducedTable: false
        }
      }
    case 'DIG_CLEAR_SELECTED':
      return {
        ...state,
        state: {
          ...state.state,
          updateTable: true,
          selectedQuestionSource: null,
          secondColumnIndex: [],
          data: {},
          secondColumns: { value: '' },
          ...action.payload
        }
      }

    case 'DIG_EDIT_QUESTION':
      const updatedState = { ...state.state }
      return {
        ...state,
        state: {
          ...updatedState,
          firstColumn: action.payload.firstColumn,
          scndColumn: action.payload.scndColumn,
          updateTable: true
        }
      }
    case 'DIG_SET_UPDATE_TABLE_STATE':
      const copyState = { ...state.state }
      return {
        ...state,
        state: {
          ...copyState,
          updateTable: action.payload.status
        }
      }
    case 'DIG_SET_TABLE_STATE':
      const stateCopy = { ...state.state }
      return {
        ...state,
        state: {
          ...stateCopy,
          displayTable: action.payload.value,
          updateTable: false
        }
      }
    case 'DIG_SET_QUESTION_OPTIONS':
      const copiedState = { ...state.state }
      return {
        ...state,
        state: {
          ...copiedState,
          updateTable: true,
          questionOptions: action.payload.values,
          newQuestionOptions: action.payload.newValues
        }
      }
    case 'DIG_CLEAR_QUESTION_OPTIONS':
      const optionState = { ...state.state }
      return {
        ...state,
        state: {
          ...optionState,
          updateTable: action.payload.clearSession ? false : true,
          questionOptions: action.payload.values,
          newQuestionOptions: action.payload.options,
          filterData: [],
          universeData: []
        }
      }
    case 'DIG_SET_TARGET_GROUP':
      const filterState = { ...state.state }
      return {
        ...state,
        state: {
          ...filterState,
          targetGroup: action.payload.data
        }
      }
    case 'DIG_SET_UNIVERSE_DATA':
      const universeState = { ...state.state }
      return {
        ...state,
        state: {
          ...universeState,
          updateTable: true,
          universeData: action.payload.data
        }
      }
    case 'SET_DIG_OPTION':
      const optionId = [...state.state.optionId]
      if (action.payload.data) {
        return {
          ...state,
          state: {
            ...state.state,
            optionId: [...optionId, action.payload.data]
          }
        }
      }
      return {
        ...state,
        state: {
          ...state.state,
          optionId: []
        }
      }
    case 'SET_DIG_INITIAL_OPTIONS_DATA':
      return {
        ...state,
        state: {
          ...state.state,
          initialOptionsData: {
            initialUniverseData: action.payload.initialUniverseData,
            initialQuestionOptions: action.payload.initialQuestionOptions,
            initialNewQuestionOptions: action.payload.initialNewQuestionOptions,
          }
        }
      }
    case 'SET_DIG_CURRENT_OPTIONS_DATA':
      return {
        ...state,
        state: {
          ...state.state,
          universeData: action.payload.universeData,
          questionOptions: action.payload.questionOptions,
          newQuestionOptions: action.payload.newQuestionOptions,
        }
      }
    case 'SET_DIG_INITIAL_OPTIONS_VALUES':
      return {
        ...state,
        state: {
          ...state.state,
          initialAndUpdateOptionsValues: {
            initialOptionsValues: action.payload.initialAndUpdateOptionsValues.initialOptionsValues,
            updateOptionsValues: action.payload.initialAndUpdateOptionsValues.updateOptionsValues
          }
        }
      }

    case 'DIG_DISABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload
        }

      }
    case 'DIG_ENABLE_SELECTED_QUESTIONS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload
        }
      }
    case 'DIG_UPDATE_ROWS':
      return {
        ...state,
        state: {
          ...state.state,
          firstColumn: action.payload,
        }

      }
    case 'DIG_UPDATE_CHECKED_NUM':
      return {
        ...state,
        state: {
          ...state.state,
          checkedNum: action.payload,
        }
      }

    default:
      return { ...state };
  }
}

export default digStateReducer;
